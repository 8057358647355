import React from "react";

export default function DrawCanvas({ _key, hazardsdetected, uploadedImage, setIsDialogOpen, setDialogImgCount }) {
    return (
        <tr style={{ verticalAlign: 'top' }}>
            <td>
                <img src={uploadedImage} style={{ width: '150px', cursor: 'pointer' }} alt="Hazard Identification" onClick={() => { setIsDialogOpen(true); setDialogImgCount(_key) }} />
            </td>
            <td colSpan={2}>
                <table className="table compact customTable">
                    <tbody>
                        {hazardsdetected?.hazards?.map((i, k) => {
                            return (
                                <tr key={k}>
                                    <td width='37%'>
                                        <div>
                                            <i className="icon icon-triangle-warning" style={{ color: "red" }} ></i>
                                            <span style={{ marginLeft: "5px" }}>{i.hazard}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <ul>
                                        {i?.mitigations.map((j, idx) => {
                                            return (
                                                <li key={idx} className="mb-10">{j}</li>
                                            )
                                        })}
                                        </ul>
                                    </td>
                                </tr>
                            )
                        }
                        )}
                    </tbody>
                </table>
            </td>
        </tr>
    )
}