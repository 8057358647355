import React, { useEffect, useState } from "react";
import { Select } from '@eds/vanilla';
import './TasksAssociatedHazardsMitigation.scss';
import { getTaskAssocoatHazardAIData } from "../../../api/AiServices.api";
import { useSelector } from "react-redux";
import Loading from "../../../components/Loading/Loading";
import _ from "lodash";
import AlertEDS from "../../../components/AlertWarn/AlertEDS";

export default function TasksAssociatedHazardsMitigation() {
  const [getData, setData] = useState({
    task: [""],
    typeofstructure: "",
  });
  const [getErr, setErr] = useState({
    task: [""],
    typeofstructure: "",
    taskAssocoatHazardData: ""
  });
  const [getTaskAssocoatHazardData, setTaskAssocoatHazardData] = useState([]);
  const [isLoading, setIsLoading] = useState({ taskAssocoatHazardData: false });
  const { headersList } = useSelector((state) => state.envVariables);

  useEffect(() => {
    const selectDOM = document.querySelector('.select');
    const select = new Select(selectDOM);
    select.init();

    return () => {
      select.destroy();
    }
  }, []);

  const handleChange = (event, k) => {
    const { name, value } = event.target;
    if (name === "task") {
      let _getErr = getErr.task;
      let _getData = getData.task;
      _getData[k] = value;
      if (value === "") {
        _getErr[k] = "Please enter task name"
        setErr({ ...getErr, [name]: _getErr });
      } else {
        _getErr[k] = ""
        setErr({ ...getErr, [name]: _getErr });
      }
      setData({ ...getData, [name]: _getData });
    }
  }

  const options = ["In-Building / Data Center", "Guyed", "Ground", "Monopole", "Rooftop", "Self Support", "Shelter", "Stealth Pole", "Tower", "Utility", "Other"];

  const selectOption = (i) => {
    setData({ ...getData, ["typeofstructure"]: i });
    setErr({ ...getErr, ["typeofstructure"]: "" });
  }

  const handleSubmit = async () => {
    const formData = getData;
    formData.modeltype = "gen_ai_task";
    await getTaskAssocoatHazardAIData(headersList, formData, setIsLoading, setErr, setTaskAssocoatHazardData);

    setTimeout(() => {
      let _totalHeight = document.querySelector('.TaskAssocoatHazardData').offsetTop;
      document.querySelector('.appcontent').scrollTo({ top: _totalHeight - 100, behavior: "smooth" });
    }, 100);
  }
  const handleClear = () => {
    setData({
      task: "",
      typeofstructure: "",
    });
    setTaskAssocoatHazardData([]);
  }

  const handleAddMore = () => {
    let x = getData.task;
    x.push('');
    setData({ ...getData, ["task"]: x });

    let y = getErr.task;
    y.push('Please enter task name');
    setErr({ ...getErr, ["task"]: y });
  }

  const deleteAddMore = (k) => {
    let x = getData.task;
    x.splice(k, 1);
    setData({ ...getData, ["task"]: x });
  }

  return (
    <div className="row TasksAssociatedHazardsMitigation">
      <div className="tile md-12 sm-12 lg-12 ">
        <div className="header">
          <div className="left">
            <div className="title">Hazards and their mitigations given a task</div>
          </div>
        </div>
        <div className="card inputData">
          <div className="row">
            <div className="md-12 sm-12 lg-12">
              <div className="form-group">

                <div className="form-element dropDiv">
                  <div className="dropDownDiv">
                    <label className="required">Please select type of structure</label>
                    <div className="select" data-type="single">
                      <button className="btn current-options" type="button">Select option</button>
                      <div className="options-list">
                        {options.map((i, k) => {
                          return (
                            <div className="item" key={k} onClick={() => selectOption(i)}>{i}</div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  {getErr.typeofstructure && <span className="validation error-message">{getErr.typeofstructure}</span>}

                  <div className="alignRight"><button className="btn" onClick={handleClear} disabled={_.isEmpty(getTaskAssocoatHazardData)}>Clear</button></div>
                </div>

                <div className="form-element inputContainer">
                  <div className="inputText">
                    <label htmlFor="input-1" className="required">
                      <span>Enter your tasks</span>
                    </label>
                    {getData.task.map((i, k) => {
                      return (<div className="taskInput" key={k}>
                        <input type="text" className={getErr.task[k] !== "" ? "invalid validation" : "validation"} name="task" value={i} onChange={(event) => handleChange(event, k)} id="input-1" placeholder="Enter your task" />
                        <span className="validation-msg error-message-required">{getErr.task[k]}</span>
                        <button className="btn" onClick={() => deleteAddMore(k)} disabled={getData.task.length === 1}><i className="icon icon-trashcan"></i></button>
                      </div>
                      )
                    })}
                    <div className="w100"> <button className="btn" onClick={handleAddMore}>Add more+</button></div>
                  </div>
                  <div className="hintContainer">
                    <div className="hintex">e.g.</div>
                    <div className="hint">Replace Radio and clear alarms.</div>
                    <div className="hint">Access radio and start troubleshooting.</div>
                  </div>
                </div>
                <div className="form-element btnDiv">
                  <button className="btn" disabled={getData.task.includes('') || _.isEmpty(getData.typeofstructure)} onClick={handleSubmit}>Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content">{isLoading.taskAssocoatHazardData ?
          (<div className="SiderBarLoader">
            <Loading />
          </div>)
          :
          (<>
            {!_.isEmpty(getErr.taskAssocoatHazardData) ?
              (<>
                <AlertEDS
                  errMsg={getErr.taskAssocoatHazardData}
                  status_code={201}
                  close={false}
                />
              </>)
              :
              (
                <>
                  {!_.isEmpty(getTaskAssocoatHazardData) &&
                    <table className="table TaskAssocoatHazardData dashed">
                      <thead>
                        <tr>
                          <th>Task</th>
                          <th>Type of Structure</th>
                          <th>Hazards</th>
                          <th>Mitigations</th>
                          <th>References</th>
                        </tr>
                      </thead>
                      <tbody>
                        {getTaskAssocoatHazardData?.map((i, k) => {
                          return (
                            <tr key={k} style={{ verticalAlign: 'top' }}>
                              <td>{i.task["task-type"]}</td>
                              <td>{i.task["type-of-structure"]}</td>
                              <td colSpan={3}>
                                {i?.hazards.map((j, idx) => {
                                  return (
                                    <table className="customTable">
                                      <tbody>
                                        <tr style={{ verticalAlign: 'top' }}>
                                          <td><div key={idx} className="hazard">{j.hazard}</div></td>
                                          <td>
                                            <div key={idx}>
                                              {j?.mitigations.map((x, key) => {
                                                return (
                                                  <div key={key} className="mitigation">{x.mitigation}</div>
                                                )
                                              })}
                                            </div>
                                          </td>
                                          <td>
                                            {j?.mitigations.map((x, key) => {
                                              return (
                                                <div key={key}>{x?.references.map((y, ind) => {
                                                  return (
                                                    <div key={ind} className="references">{y}</div>
                                                  )
                                                })}</div>
                                              )
                                            })}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  )
                                })}
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  }
                </>
              )
            }
          </>
          )}
        </div>
      </div>
    </div>
  );
}