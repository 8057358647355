import { URL } from "../utils/Constant";

export const getUploadImages = async (headers, formData, setIsLoading, setError, getAIdata) => {
    setIsLoading((val) => ({ ...val, imageLoading: true }));
    try {
        const resp = await fetch(URL.ERICSION.UPLOAD_MULTIPLE_IMG, {
            method: "POST",
            body: formData,
            headers: headers,
        });
        if (resp.ok) {
            const data = await resp.json();
            if (data.status_code === 200) {
                const imageUrl = data.data[0];
                setError((val) => ({ ...val, imageError: "" }));
                getAIdata(imageUrl);
            } else {
                setError((val) => ({ ...val, imageError: data.message }));
            }

        } else {
            setError((val) => ({ ...val, imageError: "Internal Server Error. Please try again", }));

        }
    } catch {
        setError((val) => ({ ...val, imageError: "Internal Server Error. Please try again", }));

    }
    setIsLoading((val) => ({ ...val, imageLoading: false }));
};

export const getHazardAIData = async (headersList, imgURL, setIsLoading, setError, _hazardsdetected) => {
    setIsLoading((val) => ({ ...val, ppehazardLoading: true }));
    const formData = JSON.stringify({ pic_url: imgURL, "modeltype": "gen_ai" })
    try {
        const resp = await fetch(URL.ERICSION.GET_HAZARD_AI_DATA, {
            method: "POST",
            body: formData,
            headers: headersList,
        });
        if (resp.ok) {
            const data = await resp.json();
            if (data.status_code === 200) {
                setError((val) => ({ ...val, ppehazardLoading: "" }));
                _hazardsdetected.push(data.data);
            } else {
                setError((val) => ({ ...val, ppehazardLoading: data.message }));
                _hazardsdetected = [];
            }

        } else {
            _hazardsdetected = [];
            setError((val) => ({
                ...val,
                ppehazardLoading: "Internal Server Error. Please try again",
            }));

        }
    } catch {
        _hazardsdetected = [];
        setError((val) => ({
            ...val,
            ppehazardLoading: "Internal Server Error. Please try again",
        }));

    }
    setIsLoading((val) => ({ ...val, ppehazardLoading: false }));
};

export const getPpeAIData = async (headersList, imgURL, setIsLoading, setError, _ppedetected) => {
    const formData = JSON.stringify({ pic_url: imgURL })

    setIsLoading((val) => ({ ...val, ppehazardLoading: true }));
    try {
        const resp = await fetch(URL.ERICSION.GET_ALL_PPE_AI_DATA, {
            method: "POST",
            body: formData,
            headers: headersList,
        });
        if (resp.ok) {
            const data = await resp.json();
            if (data.status_code === 200) {
                setError((val) => ({ ...val, ppehazardLoading: "" }));
                _ppedetected.push(data.data);
            } else {
                setError((val) => ({ ...val, ppehazardLoading: data.message }));
                _ppedetected = [];
            }
        } else {
            _ppedetected = [];
            setError((val) => ({ ...val, ppehazardLoading: "Internal Server Error. Please try again" }));
        }
    } catch {
        _ppedetected = [];
        setError((val) => ({ ...val, ppehazardLoading: "Internal Server Error. Please try again" }));
    }
    setIsLoading((val) => ({ ...val, ppehazardLoading: false }));
};

export const getTaskAssocoatHazardAIData = async (headersList, formData, setIsLoading, setErr, setTaskAssocoatHazardData) => {
    setIsLoading((val) => ({ ...val, taskAssocoatHazardData: true }));
    const _formData = JSON.stringify(formData);
    try {
        const resp = await fetch(URL.ERICSION.GET_HAZARD_AI_DATA, {
            method: "POST",
            body: _formData,
            headers: headersList,
        });
        if (resp.ok) {
            const data = await resp.json();
            if (data.status_code === 200) {
                setErr((val) => ({ ...val, taskAssocoatHazardData: "" }));
                setTaskAssocoatHazardData(data.data);
            } else {
                setErr((val) => ({ ...val, taskAssocoatHazardData: data.message }));
                setTaskAssocoatHazardData([]);
            }

        } else {
            setTaskAssocoatHazardData([]);
            setErr((val) => ({
                ...val,
                taskAssocoatHazardData: "Internal Server Error. Please try again",
            }));

        }
    } catch {
        setTaskAssocoatHazardData([]);
        setErr((val) => ({
            ...val,
            taskAssocoatHazardData: "Internal Server Error. Please try again",
        }));

    }
    setIsLoading((val) => ({ ...val, taskAssocoatHazardData: false }));
};

export const getUploadFolder = async (headers, formData, getData, setIsLoading, setError, setcreateHazardIdenti) => {
    setIsLoading((val) => ({ ...val, folderUploadLoading: true }));

    try {
        const resp = await fetch("https://app-api-esw-dev-007.azurewebsites.net/api/v1/hazard-identification?site_id=" + getData.siteID + "&site_type=" + getData.siteType + "&customer=" + getData.customer, {
            method: "POST",
            body: formData,
            headers: headers,
        });
        if (resp.ok) {
            const data = await resp.json();
            if (resp.status === 200) {
                setError((val) => ({ ...val, folderUploadLoading: "" }));
                setcreateHazardIdenti(data);
            } else {
                setError((val) => ({ ...val, folderUploadLoading: data.message }));
                setcreateHazardIdenti([]);
            }
        } else {
            setcreateHazardIdenti([]);
            setError((val) => ({
                ...val,
                folderUploadLoading: "Internal Server Error. Please try again",
            }));
        }
    } catch {
        setcreateHazardIdenti([]);
        setError((val) => ({
            ...val,
            folderUploadLoading: "Internal Server Error. Please try again",
        }));
    }
    setIsLoading((val) => ({ ...val, folderUploadLoading: false }));
};

export const getcreateHazardIdentification = async (headersList, setIsLoading, setError, createHazardIdenti, setHazardIdenti, setListHazardIdenti) => {
    setIsLoading((val) => ({ ...val, getHazardIdentification: true }));
    try {
        const resp = await fetch("https://app-api-esw-dev-007.azurewebsites.net/api/v1/hazard-identification/" + createHazardIdenti.id, {
            method: "GET",
            headers: headersList,
        });
        if (resp.ok) {
            const data = await resp.json();
            if (resp.status === 200) {
                setHazardIdenti(data);
            } else {
                setError((val) => ({ ...val, getHazardIdentification: data.message }));
                setHazardIdenti([]);
            }
        }
    } catch {
        setHazardIdenti([]);
        setError((val) => ({ ...val, getHazardIdentification: "Internal Server Error. Please try again" }));
    }
    setIsLoading((val) => ({ ...val, getHazardIdentification: false }));
};



export const getListHazardIdentification = async (headersList, setIsLoading, setError, setListHazardIdenti) => {
    setIsLoading((val) => ({ ...val, getListHazardIdentification: true }));
    try {
        const interval = setInterval(async () => {
            const resp2 = await fetch("https://app-api-esw-dev-007.azurewebsites.net/api/v1/hazard-identifications", {
                method: "GET",
                headers: headersList,
            });

            const data2 = await resp2.json();
            if (resp2.status === 200) {
                const listHazards = data2.filter(i => i.id === "REQ-HID-20241107-7d2d40b4")[0];
                setListHazardIdenti(listHazards);
                if (listHazards.status === "BATCH_COMPLETED" || listHazards.status === "BATCH_FAILED") {
                    clearInterval(interval);
                }

            }
        }, 10000);
    } catch {
        setListHazardIdenti([]);
        setError((val) => ({ ...val, getListHazardIdentification: "Internal Server Error. Please try again" }));
    }
    setIsLoading((val) => ({ ...val, getListHazardIdentification: false }));
};